
<template>
	<div>
		<div class="upload">
			<div class="img_mode" v-for="(item, index) in path_list" :key="index" :style="'height:' + height +';width:'+ width" @mouseenter.stop="over(index)" @mouseleave="out">
				<img :src="item.path" />
				<transition name="el-fade-in-linear">
					<div v-show="curIndex == index" class="transition-box">
						<div class="mask">
							<i class="el-icon-delete" @click="remove(index)"></i>
							<i class="el-icon-zoom-in" @click="enlarge(index)" v-if="bigBox"></i>
						</div>
					</div>
				</transition>
			</div>
			<div class="select_mode" :style="'height:' + height +';width:'+ width" v-if="isLimit" v-loading="load">
				<input type="file" @change="change($event)" ref="file"  :multiple="isMultiple" />
				<img :src="selectImgPath || selectImg" :width="selectImgWidth || '50%'"/>
			</div>
		</div>
		<el-dialog :visible.sync="isShow" center append-to-body>
			<div class="big_img_mode">
				<img :src="bigImg" />
			</div>
		</el-dialog>
	</div>
</template>

<script>
import selectImg from '/src/components/upload/img/selectedImg.png'

	export default {
		props: {
			height: {
        type: String,
        default: "120px"
      },
			width: {
        type: String,
        default: "120px"
      },
			selectImgPath: String,
			selectImgWidth: {
				type: String,
				default: '50%'
			},
			bigBox: Boolean,

			isMultiple: Boolean,
			limit_size: Number,
			quality: Number,
			limit: Number,
			isCompress: Boolean,

			isChangeUpload: false,
			action: '',
			param: '',
      isBase64: Boolean,
			data: Object,
			success: Function
		},

		data() {
			return {
				selectImg,
				path_list: [],
				curIndex: -1,
				bigImg: '',
				isShow: false,
				isLimit: true,
				load: false
			}
		},

		watch:{
			path_list(){
				if(this.path_list.length >= this.limit_size){
					this.isLimit = false
				}else{
					this.isLimit = true
				}
				this.load = false
				this.curIndex = -1
			}
		},

		mounted() {

		},

		methods: {

			over(index) {
				this.curIndex = index
			},


			out() {
				this.curIndex = -1
			},


			change() {
				this.load = true
				var That = this;
				let fileList = this.$refs.file.files
				if(!fileList.length){
					this.load = false
					return;
				}
				for (var i = 0; i < fileList.length; i++) {
          var file_temp = fileList[i]
          if(this.isBase64){
            let reader = new FileReader();
            reader.fileName = file_temp.name
            reader.readAsDataURL(file_temp);
            reader.onload = (data) => {

              this.canvasDataURL(data.currentTarget.result, {fileSize: data.total, quality: this.quality }, baseCode=>{
                if(this.isChangeUpload){
                  this.changeUpload(baseCode, reader.fileName);
                }else{
                  this.path_list.push({
                    path: baseCode,
                    fileName: reader.fileName
                  })
                }
              })
            };
          } else {
            this.changeUpload2(file_temp);
          }
        }
			},


			remove(index) {
				this.path_list.splice(index, 1)
			},


			enlarge(index) {
				this.isShow = true
				this.bigImg = this.path_list[index].path
			},


			changeUpload(baseCode, fileName){
				let formData = new FormData();
				formData.append(this.param, baseCode);
				formData.append('fileName', fileName);
				for (var item in this.data) {
					formData.append(item, this.data[item])
				}
				this.$axios.post(this.action, formData).then(response=>{
					if(response.data.message == "succ"){
						this.success()
					}
					this.load = false
				}).catch(e=>{
					this.load = false
				})
			},


      changeUpload2(item){
        let formData = new FormData();
				formData.append('file', item);
				this.$axios.post(this.action, formData).then(response=>{
					if(response.data.success){
            this.path_list.push({
              path: response.data.data.url,
              fileName: response.data.data.name
            })
					}
					this.load = false
				}).catch(e=>{
					console.log(e)
					this.load = false
				})
      },


			upload( uploadPath, path_list, callback ){
				var formData = new FormData()
				if(!path_list){
					this.path_list.forEach( item=>{
						formData.append("files", this.convertBase64UrlToBlob(item.path), item.fileName)
					})
				}else{
					path_list.forEach( item=>{
						formData.append("files", this.convertBase64UrlToBlob(item.path), item.fileName)
					})
				}
				let headers = {headers: {"Content-Type": "multipart/form-data"}}

				this.$axios.post(uploadPath, formData, headers).then( response=>{
					if(response.data.message == "succ"){

						callback("succ", response.data.result )
					}else{
						this.$message.error("文件上传失败")
						callback("error" )
					}
				}).catch(error=>{
					if(error == 'timeout'){
						this.$message.error("文件上传超时")
					}
					this.$message.error("文件上传异常")
					console.log(error)
					callback("error")
				})
			},
			getName(){
				return this.path_list.fileName
			},

			getPaths(){
				let paths = ""
				for(var i = 0; i < this.path_list.length; i++){
					if(i == 0){
						paths = this.path_list[i].path
					}else{
						paths += ","+this.path_list[i].path
					}
				}
				return paths;
			},

			photoCompress(){

			},


    canvasDataURL(path, obj, callback){
      var img = new Image();
      img.src = path;
      img.onload = function(){
        var that = this;

        var w = that.width,
            h = that.height,
            scale = w / h;
        w = obj.width || w;
        h = obj.height || (w / scale);
        var quality = 0.7;
        this.limit = this.limit? this.limit : 1

        var canvas = document.createElement('canvas');
        var ctx = canvas.getContext('2d');

        var anw = document.createAttribute("width");
        anw.nodeValue = w;
        var anh = document.createAttribute("height");
        anh.nodeValue = h;
        canvas.setAttributeNode(anw);
        canvas.setAttributeNode(anh);
        ctx.drawImage(that, 0, 0, w, h);

        if(obj.quality && obj.quality <= 1 && obj.quality > 0) quality = obj.quality;

        var base64 = "";
        let str = path.match(/data:(.*?);base64/)
                if(this.isCompress){

          base64 = obj.fileSize > (this.limit * 1024)? canvas.toDataURL(str[1], quality) : canvas.toDataURL('image/jpeg');

          }else{
          base64 = canvas.toDataURL(str[1], quality);
        }

        callback(base64);
      }
    },


			convertBase64UrlToBlob(urlData){

	    	    var bytes=window.atob(urlData.split(',')[1]);

	    	    var ab = new ArrayBuffer(bytes.length);
	    	    var ia = new Uint8Array(ab);
	    	    for (var i = 0; i < bytes.length; i++) {
	    	        ia[i] = bytes.charCodeAt(i);
	    	    }
	    	    return new Blob( [ab] , {type : 'image/png'});
	    	},
        removeAll(){
          this.path_list = []
        }
		}
	}
</script>

<style scoped="upload">
	.upload {
		width: 100%;
		overflow: hidden;
		display: flex;
		flex-wrap: wrap;
		padding: 10px 4px;
		box-sizing: border-box;
	}

	.select_mode {
		width: 120px;
		height: 120px;
		display: flex;
		justify-content: center;
		align-items: center;
		border: 1px solid #B6B6B6;
		position: relative;
		box-sizing: border-box;
	}
	.select_mode:hover{
		border: 1px solid #00A2E9;
	}

	.select_mode img {

		position: absolute;
		z-index: 1;
		cursor: pointer;
	}

	.select_mode input[type='file'] {
		width: 100%;
		height: 100%;
		opacity: 0;
		cursor: pointer;
		z-index: 2;
	}

	.img_mode {
		margin-right: 10px;
		margin-bottom: 10px;
		box-shadow: 0px 1px 5px #CCCCCC;
		position: relative;
	}

	.mask {
		background: rgb(0, 0, 0, .5);
		height: 100%;
		width: 100%;
		position: absolute;
		top: 0px;
		left: 0px;
		display: flex;
		justify-content: center;
		align-items: center;
	}

	.img_mode img {
		width: 100%;
		height: 100%;
		object-fit: cover;
	}

	.mask i {
		font-family: element-icons!important;
		color: #FFFFFF;
		font-size: 25px;
		margin: 0px 8px;
		cursor: pointer;
	}
	.big_img_mode{
		text-align: center;
		width: 100%;
		height: 100%;

		max-height: 400px;
	}
	.big_img_mode img{
		max-width: 400px;
    	max-height: 400px;
	}
</style>
