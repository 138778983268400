import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store'

Vue.use(VueRouter)

const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location, onResolve, onReject) {
if (onResolve || onReject) return originalPush.call(this, location, onResolve, onReject)
return originalPush.call(this, location).catch(err => err)
}

const routes = [
    {
        path: '/',
        name: 'Home',

        component: () => import( '../views/Home.vue'),
        children: [
            {
                path: '/',
                name: 'Index',
                component: () => import( '../views/main/Index.vue'),
            },
            {
                path: '/login',
                name: 'Login',
                component: () => import( '../views/Login.vue'),
            },
            {
                path: '/forget_password',
                name: 'ForgetPassword',
                component: () => import( '../views/ForgetPassword.vue'),
            },
            {
                path: '/register',
                name: 'Register',
                component: () => import( '../views/Register.vue'),
            },
            {
                path: '/index',
                name: 'Index',
                component: () => import( '../views/main/Index.vue'),
            },
            {
                path: '/goods',
                name: 'Goods',
                redirect: '/goods/goodsList',
                component: () => import( '../views/main/goods/Index.vue'),
                children: [
                    {
                        path: '/goods/goodsList',
                        name: 'GoodsIndex',
                        component: () => import( '../views/main/goods/Goods.vue'),
                    },
                    {
                        path: '/goods/goods_purchase',
                        name: 'GoodsPurchase',
                        component: () => import( '../views/main/goods/GoodsPurchase.vue'),
                    },
                    {
                        path: '/goods/goods_history',
                        name: 'GoodsHistory',
                        component: () => import( '../views/main/goods/GoodsHistory.vue'),
                    },
                    {
                        path: '/goods/:id',
                        name: 'GoodsDetail',
                        component: () => import( '../views/main/goods/Detail.vue'),
                    }
                ]
            },
            {
                path: '/member',
                name: 'Member',
                redirect: '/member/index',
                component: () => import( '../views/main/member/Index.vue'),
                children: [
                    {
                        path: '/member/index',
                        name: 'MemberIndex',
                        meta:{requireAuth: true,},
                        component: () => import( '../views/main/member/Member.vue'),
                    },
                    {
                        path: '/member/send_order',
                        name: 'SendOrder',
                        meta:{requireAuth: true,},
                        component: () => import( '../views/main/member/SendOrder.vue'),
                    },
                    {
                        path: '/member/order',
                        name: 'Order',
                        meta:{requireAuth: true,},
                        component: () => import( '../views/main/member/Order.vue'),
                    },
                    {
                        path: '/member/receipt',
                        name: 'Receipt',
                        meta:{requireAuth: true,},
                        component: () => import( '../views/main/member/Receipt.vue'),
                    },
                    {
                        path: '/member/recharge',
                        name: 'Recharge',
                        meta:{requireAuth: true,},
                        component: () => import( '../views/main/member/Recharge.vue'),
                    },
                    {
                        path: '/member/money_log',
                        name: 'MoneyLog',
                        meta:{requireAuth: true,},
                        component: () => import( '../views/main/member/MoneyLog.vue'),
                    },
                    {
                        path: '/member/check_pieces',
                        name: 'CheckPieces',
                        meta:{requireAuth: true,},
                        component: () => import( '../views/main/member/CheckPieces.vue'),
                    },
                    {
                        path: '/member/receive_order',
                        name: 'ReceiveOrder',
                        meta:{requireAuth: true,},
                        component: () => import( '../views/main/member/ReceiveOrder.vue'),
                    },
                    {
                        path: '/member/vip',
                        name: 'Vip',
                        meta:{requireAuth: true,},
                        component: () => import( '../views/main/member/Vip.vue'),
                    },
                    {
                        path: '/member/edit_password',
                        name: 'EditPassword',
                        meta:{requireAuth: true,},
                        component: () => import( '../views/main/member/EditPassword.vue'),
                    },
                    {
                        path: '/member/edit_member',
                        name: 'EditMember',
                        meta:{requireAuth: true,},
                        component: () => import( '../views/main/member/EditMember.vue'),
                    },
                    {
                        path: '/member/inviter',
                        name: 'Inviter',
                        meta:{requireAuth: true,},
                        component: () => import( '../views/main/member/Inviter.vue'),
                    },
                    {
                        path: '/member/other_site',
                        name: 'OtherSite',

                        component: () => import( '../views/main/member/OtherSite.vue'),
                    },
                    {
                        path: '/member/site_apply',
                        name: 'SiteApply',
                        component: () => import( '../views/main/member/SiteApply.vue'),
                    },
                    {
                        path: '/member/interface',
                        name: 'Interface',

                        component: () => import( '../views/main/member/Interface.vue'),
                    },





                    {
                        path: '/member/teamwork',
                        name: 'Teamwork',
                        component: () => import( '../views/main/member/Teamwork.vue'),
                    },
                    {
                        path: '/member/about',
                        name: 'About',
                        component: () => import( '../views/main/member/About.vue'),
                    },
                    {
                        path: '/member/guide',
                        name: 'Guide',

                        component: () => import( '../views/main/member/Guide.vue'),
                    },
                    {
                        path: '/member/check_order',
                        name: 'CheckOrder',
                        component: () => import( '../views/main/member/CheckOrder.vue'),
                    },
                    {
                        path: '/member/chose_goods',
                        name: 'ChoseGoods',
                        component: () => import( '../views/main/member/ChoseGoods.vue'),
                    },
                    {
                        path: '/member/stop_area',
                        name: 'StopArea',
                        component: () => import( '../views/main/member/StopArea.vue'),
                    },
                    {
                        path: '/member/withdraw',
                        name: 'Withdraw',
                        component: () => import( '../views/main/member/Withdraw.vue'),
                    },
                    {
                        path: '/member/advice',
                        name: 'Advice',
                        component: () => import( '../views/main/member/Advice.vue'),
                    },
                    {
                        path: '/member/advice_list',
                        name: 'AdviceList',
                        component: () => import( '../views/main/member/AdviceList.vue'),
                    },
                ]
            },
            {
                path: '/news',
                name: 'News',
                component: () => import( '../views/main/news/Index.vue'),
                children: [
                    {
                        path: '/news/:id',
                        name: 'Detail',
                        component: () => import( '../views/main/news/Detail.vue'),
                    },
                ]
            },
            {
                path: '/about',
                name: 'Abouts',
                component: () => import( '../views/About.vue'),
            },





        ]
    },
    {
        path: '/wxLogin',
        name: 'WxLogin',
        component: () => import( '../views/WxLogin.vue'),
    },
    {
        path: '/m_index',
        name: 'M_index',
        component: () => import( '../views/mobile/Index.vue'),
    },
    {
        path: '/m_login',
        name: 'M_login',
        component: () => import( '../views/mobile/Login.vue'),
    },
    {
        path: '/m_register',
        name: 'M_register',
        component: () => import( '../views/mobile/Register.vue'),
    },
    {
        path: '/m_forgetpassword',
        name: 'M_forgetpassword',
        component: () => import( '../views/mobile/ForgetPassword.vue'),
    },
    {
        path: '/m_goodsall',
        name: 'M_goodsall',
        redirect: '/goods/goodsList',
        component: () => import( '../views/main/goods/Index.vue'),
        children: [
            {
                path: '/goods/goodsList',
                name: 'GoodsIndex',
                component: () => import( '../views/main/goods/Goods.vue'),
            },
            {
                path: '/goods/goods_purchase',
                name: 'GoodsPurchase',
                component: () => import( '../views/main/goods/GoodsPurchase.vue'),
            },
            {
                path: '/goods/goods_history',
                name: 'GoodsHistory',
                component: () => import( '../views/main/goods/GoodsHistory.vue'),
            },
            {
                path: '/goods/:id',
                name: 'GoodsDetail',
                component: () => import( '../views/main/goods/Detail.vue'),
            }
        ]
    },
    {
        path: '/m_guide',
        name: 'M_guide',
        component: () => import( '../views/mobile/Guide.vue'),
    },
    {
        path: '/m_othersite',
        name: 'M_othersite',
        component: () => import( '../views/mobile/OtherSite.vue'),
    },
]

const router = new VueRouter({
    mode: 'hash',
    base: process.env.BASE_URL,
    routes
})

router.beforeEach((to, from, next) => {
    if (to.matched.some(r => r.meta.requireAuth)) {
        var token = localStorage.getItem('dftToken');
        if (token) {
            next();
        }
        else {
            next({
                path: '/login',
                query: {redirect: to.path}
            })
        }
    }
    else {
        next();
    }
})


export default router
