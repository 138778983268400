import JSEncrypt from 'jsencrypt';
import JSEncryptLong from 'encryptlong';



export default {
  publicKey: 'MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQCFTgpZV/rGPaQq8Ifz0TGkJAwOk9VmQ/WB3+CUlOL9/hgHVEquyRkkZr8DKKeNxrtzfx7EMp/FBGYCSyFERycNnJtD2Zkbz7DKJIXu1Q6yGl6gWHgoH2IEL+jSGRd/YCZ+hcTMBRNtXoxysYu76RUGCVmDtlXlQz3mRiqcSc5MNQIDAQAB',


  encryptByPublicKey(content){
    let encryptStr = new JSEncrypt();
    encryptStr.setPublicKey(this.publicKey);
    let data = encryptStr.encrypt(content.toString());
    return data;
  },

  encryptByPublicLongKey(content){
    let encryptStr = new JSEncryptLong();
    encryptStr.setPublicKey(this.publicKey);
    let data = encryptStr.encryptLong(content.toString());
    return data;
  }
}
