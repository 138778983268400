var proxyConfig = {

    dev: {

        serviceUrl: 'http://175.178.7.20:6060/'
    },



    prod: {

        serviceUrl: document.location.protocol + "//" + document.location.host
    }
}

export default (process.env.NODE_ENV === 'development' ? proxyConfig.dev : proxyConfig.prod)
