import axios from '@/http'
import Message from '@/plugins/element/message'
import store from '@/store'
import { removeToken } from './auth'
import router from '../router'
import { appName, moduleConfig, server, defaultLang, env, defaultApp } from '@/conf/app'




function strRandom(n) {
	let chars = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', 'a', 'b', 'c', 'd', 'e', 'f', 'g', 'h', 'i', 'j', 'k', 'l', 'm', 'n', 'o', 'p', 'q', 'r', 's', 't', 'u', 'v', 'w', 'x', 'y', 'z']
	let res = ''
	for (let i = 0; i < n; i++) {
		let id = Math.ceil(Math.random() * 35)
		res += chars[id]
	}
	return res
}

function urlAssemble(params) {
	let lang = defaultLang
	let local = localStorage.getItem('lang')
	if (typeof local !== 'undefined' && local !== null) {
		lang = local
	}
	let username = localStorage.getItem("phone") ? localStorage.getItem("phone") : '';
	params = params ? '&' + params : '';
	return 'env=' + env +
		'&distinctRequestId=' + strRandom(32) +
		'&log_origin=rest' +
		'&origin_system=' + appName +
		'&lang=' + lang +
		'&token=' + username +
		'&platform_name=system' +
		'&timestamp=' + Date.parse(new Date()) +
		'&soa_come_from=msgw' +
		'&appcode=' + defaultApp.appcode +
		'&cloned=' + defaultApp.cloned + params;
}


export default {

	post(data) {
		let qs = require('qs')
		let params = data.type == "array" ? qs.stringify(data.params, { arrayFormat: 'repeat' }) : (Object.prototype.toString.call(data.params) == '[object FormData]' ? data.params : qs.stringify(data.params));
		let headers = data.headers == "form-data" ? { headers: { "Content-Type": "multipart/form-data" } } : data.headers;
		if (Object.prototype.toString.call(data.params) != '[object FormData]') {
			params = urlAssemble(params);
		}
		var r = axios.post(data.url, params, headers)

		r.then(response => {

			if (response.data.success) {
				if (data.success) data.success(response.data.data, response);
			} else if (!response.data.success) {
				if (response.data.code === '501') {
					Message.error(response.data.msg)
					store.commit("getUserInfo", {});
					store.commit("setIsLogin", false);
					router.push("/login");
					localStorage.removeItem("dftToken");
					localStorage.removeItem("phone");
					removeToken();
					return Promise.reject('error')
				}
				if (!data.closeErrInfo) Message.error(response.data.msg)
				if (data.error) data.error(response.data.msg, response);
			} else if (response.data.message == "info") {
				if (!data.closeInfo) Message.defaultMsg(response.data.result)
				if (data.info) data.info(response.data.result, response);
			} else if (response.data.message == "warning") {
				if (!data.closeWarningInfo) Message.warning(response.data.result)
				if (data.warning) data.warning(response.data.result, response);
			}

			if (data.then) data.then(response);

			if (data.finally) data.finally();
		})

		r.catch(error => {

			if (data.finally) data.finally();

			if (data.catch) data.catch(error);

			if (!data.closeCodeInfo) {
				if (error.response && error.response.status) {

					switch (error.response.status) {
						case 401:
							break;
						case 404:
							Message.error('请求错误')
							break;
						case 403:
							Message.error('无权访问')
							break;
						case 413:
							Message.error('请求实体内容过大, statusCode:' + error.response.status)
							break;
						case 500:
							Message.error('系统异常')
							break;
						case 504:
							Message.error('无法连接服务器')
							break;
						default:
							Message.error('发生错误, statusCode:' + error.response.status)
							break;
					}
				} else if (error.code) {
					switch (error.code) {
						case "ECONNABORTED":
							Message.error("请求超时, 网络不给力哦")
							break;
						default:
							Message.error('发生错误, errorCode:' + error.code)
							break;
					}
				}
			}
		})
	}
}
