export default function(timestamp, format) {
  if (!timestamp) {
    return '-- --'
  }
  var jsdate = ((timestamp) ? new Date(timestamp * 1000) : new Date())
  var pad = function(n, c) {
    if ((n = n + '').length < c) {
      return new Array(++c - n.length).join('0') + n
    } else {
      return n
    }
  }
  var textWeekdays = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday']
  var textChinaWeekdays = ['星期日', '星期一', '星期二', '星期三', '星期四', '星期五', '星期六']
  var txtOrdin = { 1: 'st', 2: 'nd', 3: 'rd', 21: 'st', 22: 'nd', 23: 'rd', 31: 'st' }
  var txtMonths = ['', 'January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December']
  var f = {

    d: function() { return pad(f.j(), 2) },
    D: function() { return f.l().substr(0, 3) },
    j: function() { return jsdate.getDate() },
    l: function() { return textWeekdays[f.w()] },
    q: function() { return textChinaWeekdays[f.w()] },
    N: function() { return f.w() + 1 },
    S: function() { return txtOrdin[f.j()] ? txtOrdin[f.j()] : 'th' },
    w: function() { return jsdate.getDay() },
    z: function() { return (jsdate - new Date(jsdate.getFullYear() + '/1/1')) / 864e5 >> 0 },

    W: function() {
      const a = f.z()
      const b = 364 + f.L() - a
      const nd = (new Date(jsdate.getFullYear() + '/1/1').getDay() || 7) - 1
      let nd2 = nd
      if (b <= 2 && ((jsdate.getDay() || 7) - 1) <= 2 - b) {
        return 1
      } else {
        if (a <= 2 && nd >= 4 && a >= (6 - nd)) {
          nd2 = new Date(jsdate.getFullYear() - 1 + '/12/31')
          return this('W', Math.round(nd2.getTime() / 1000))
        } else {
          return (1 + (nd <= 3 ? ((a + nd) / 7) : (a - (7 - nd)) / 7) >> 0)
        }
      }
    },

    F: function() { return txtMonths[f.n()] },
    m: function() { return pad(f.n(), 2) },
    M: function() { return f.F().substr(0, 3) },
    n: function() { return jsdate.getMonth() + 1 },
    t: function() {
      var n
      if ((n = jsdate.getMonth() + 1) === 2) {
        return 28 + f.L()
      } else {
        if ((n & 1 && n < 8) || (!(n & 1) && n > 7)) {
          return 31
        } else {
          return 30
        }
      }
    },


    L: function() {
      var y = f.Y()
      return (!(y & 3) && (y % 1e2 || !(y % 4e2))) ? 1 : 0
    },

    Y: function() { return jsdate.getFullYear() },
    y: function() { return (jsdate.getFullYear() + '').slice(2) },


    a: function() { return jsdate.getHours() > 11 ? 'pm' : 'am' },
    A: function() { return f.a().toUpperCase() },
    B: function() {

      var off = (jsdate.getTimezoneOffset() + 60) * 60
      var theSeconds = (jsdate.getHours() * 3600) + (jsdate.getMinutes() * 60) + jsdate.getSeconds() + off
      var beat = Math.floor(theSeconds / 86.4)
      if (beat > 1000) beat -= 1000
      if (beat < 0) beat += 1000
      if ((String(beat)).length === 1) beat = '00' + beat
      if ((String(beat)).length === 2) beat = '0' + beat
      return beat
    },
    g: function() { return jsdate.getHours() % 12 || 12 },
    G: function() { return jsdate.getHours() },
    h: function() { return pad(f.g(), 2) },
    H: function() { return pad(jsdate.getHours(), 2) },
    i: function() { return pad(jsdate.getMinutes(), 2) },
    s: function() { return pad(jsdate.getSeconds(), 2) },




    O: function() {
      var t = pad(Math.abs(jsdate.getTimezoneOffset() / 60 * 100), 4)
      if (jsdate.getTimezoneOffset() > 0) {
        t = '-' + t
      } else {
        t = '+' + t
      }
      return t
    },
    P: function() {
      var O = f.O()
      return (O.substr(0, 3) + ':' + O.substr(3, 2))
    },

    T: function() { return f.Y() + '-' + f.m() + '-' + f.d() + ' ' + f.H() + ':' + f.i() + ':' + f.s() },



    c: function() { return f.Y() + '-' + f.m() + '-' + f.d() + 'T' + f.h() + ':' + f.i() + ':' + f.s() + f.P() },

    U: function() { return Math.round(jsdate.getTime() / 1000) }
  }
  return format.replace(/[\\]?([a-zA-Z])/g, function(t, s) {
    let ret
    if (t !== s) {

      ret = s
    } else if (f[s]) {

      ret = f[s]()
    } else {

      ret = s
    }
    return ret
  })
}


export function date2Timestamp(date) {
  return parseInt(date.getTime() / 1000)
}


export function timestamp2Date(timestamp) {
  return new Date(timestamp * 1000)
}




export function str2Timestamp(date) {
  date = date.replace(/-/g, '/')
  return date2Timestamp(new Date(date))
}



export function standard2Timestamp(date) {
  return parseInt(new Date(date).getTime() / 1000)
}
