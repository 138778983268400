
import upload from './upload/Upload'


const myPlugin = {


    install (Vue, options) {

        Vue.component('upload', upload)
    }
}


export default myPlugin
