import yaml from 'js-yaml'
import Cookies from 'js-cookie'


let configDef = {
  env: 'online',

  appName: 'giftweb-business',
  appCode: '1',

  moduleConfig: {
  },
  
  bbsOldAdminHost: 'http://xxxx/',

  h5Host: 'https://test-k8s.taqu.cn/h5/html',

  gwHost: 'https://test-gw-k8s-web.taqu.cn/',
  h5Css: 'https://test-k8s.taqu.cn/h5/html/community/masterplate/gift_message/release/css/index.css',
  h5Js: 'https://test-k8s.taqu.cn/h5/html/community/masterplate/gift_message/release/js/index.js',

  imgHost: {
    forumimg01: 'https://forum01.jiaoliuqu.com/',
    miscimg01: 'https://miscimg01.jiaoliuqu.com/',
    avatar01: 'https://avatar01.jiaoliuqu.com/'
  },

  server: process.env.VUE_APP_GATEWAY,
  defaultLang: process.env.VUE_APP_DEFAULT_LANG,

  appScheme: {
    appcode1: 'ushengsheng22.xjb://xingjiabi',
    appcode101: 'ushengsheng22.xjb://xingjiabi',
    appcode102: 'ushengsheng22.xjb://xingjiabi'
  }
}
let configVal = {}
if (window.configVal !== undefined) {
  configVal = yaml.load(window.configVal)
}

configVal = Object.assign(configDef, configVal)


const match1 = configVal.server.match(/\.(test[1-5]{0,1})\./)
if (match1 !== null && match1.length > 1) {
  const match2 = window.location.origin.match(/\.(test[1-5]{0,1})\./)
  if (match2 !== null && match2.length > 1) {
    if (match1[1] !== match2[1]) {
      configVal.server = configVal.server.replace(match1[1], match2[1])
    }
  }
}

export const env = window.location.href.search('.gray.') > -1 ? 'gray' : configVal.env
export const appName = configVal.appName
export const appCode = configVal.appCode
export const moduleConfig = configVal.moduleConfig
export const imgHost = configVal.imgHost
export const server = configVal.server
export const defaultLang = configVal.defaultLang
export const appScheme = configVal.appScheme
export const h5Host = configVal.h5Host
export const bbsOldAdminHost = configVal.bbsOldAdminHost
export const gwHost = configVal.gwHost
export const h5Js = configVal.h5Js
export const h5Css = configVal.h5Css


export const platforms = [
  { id: 1, name: 'android' },
  { id: 2, name: 'iphone' },
  { id: 3, name: 'ipad' },
  { id: 4, name: 'wap' }
]

export const levelOperateSupervise =
{ status: 0, start_time: '2020-01-12 00:00:00', end_time: '2020-02-21 18:00:00' }


let app = Cookies.get('app')
if (!app) {
  app = {
    name: '他趣',
    appcode: 1,
    cloned: 1,
    appcode_cloned: '1#1',
    primary: 1
  }
  Cookies.set('app', JSON.stringify(app))
}


export const smsOrPush = 1000000

export const defaultApp = JSON.parse(Cookies.get('app'))
