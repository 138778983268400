<template>
    <div id="app">
        <router-view></router-view>
    </div>
</template>

<script>

export default {
    name: 'app',
    created(){
        this.IsPC();
        this.$store.commit('getTenantInfo')
    },
    methods: {

        IsPC(){
            var userAgentInfo = navigator.userAgent;
            var Agents = new Array("Android", "iPhone", "SymbianOS", "Windows Phone", "iPad", "iPod");

            var flag = true;
            for (var v = 0; v < Agents.length; v++) {
                if (userAgentInfo.indexOf(Agents[v]) > 0) {
                  flag = false;
                  break;
                }
            }

            if(!flag){
                var pathname = window.location.pathname;
                console.log("地址:" + pathname)
                if(pathname == '/business/wxLogin'){
                    return;
                }
                var data = {"/business/index":"/m_index",
                            "/":"/m_index",
                            "/index":"m_index",
                            "/business/goods/goodsList":"/shop",
                            "/business/member/index":"/member",





                            "/business/member/order":"/order",







                            "/business/login":"/login",
                            "/business/register":"/register",
                            "/business/forget_password":"/forget_password",
                            "/business/index":"/m_index",
                            "/business/login":"/m_login",
                            "/business/register":"/m_register",
                            "/business/forget_password":"/m_forgetpassword",
                            "/business/shop":"/m_goodsall",
                            "/business/guide":"/m_guide",
                            "/business/other_site":"/m_othersite"
                            };

                var toPathName = '/m_index';
                for(var key in data){
                    if(pathname.indexOf(key) != -1){
                        toPathName = data[key];
                        break;
                    }
                }


                var host = window.location.host;


                if (host.indexOf('192')!=-1){

                    var href = window.location.protocol + '//' + window.location.hostname + ':3003' + toPathName;


                }else{

                  this.$router.push(toPathName)




                }
            }
        },
    }
}
</script>

<style>
*{
    margin: 0;
    padding: 0;
}
html,body,#app{
    font-family: "Avenir", Helvetica, Arial, sans-serif;
}
#app{
    height: 100%;
}
ul{
    list-style: none;
}
a{
    text-decoration: none;
    color: #333;
}
.clearfix::after{
    content: '';
    display: block;
    clear: both;
}
.fr{
    float: right;
}
.fl{
    float: left;
}
body,html{
    height: 100%;
}
.wrap{
    width: 1200px;
    margin: 0 auto;
}
#app {

}
.d_flex{
    display: flex;
}
</style>
