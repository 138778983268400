

import axios from 'axios'
import router from '@/router/index'
import { getToken } from '@/utils/auth'
import rsa from "@/utils/rsa";
import store from '@/store'

import { Base64 } from 'js-base64'

axios.defaults.timeout = 6000000
axios.defaults.baseURL = process.env.NODE_ENV == "development" ? '/api' : ""
//axios.defaults.baseURL= ''
axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded;charset=UTF-8'

var isOpenRsa = true;

axios.interceptors.request.use(
  config => {
    let type = Object.prototype.toString.call(config.data);
    var isFilter = false;
    if (store.state.filterUrlInfo) {
      store.state.filterUrlInfo.forEach(item => {
        if (config.url == item) {
          isFilter = true;
          return;
        }
      })
    }
    var isOpenCheck = false;
    if (store.state.isOpenCheck) {
      isOpenCheck = store.state.isOpenCheck == '0' ? false : true;
    }

    if (isOpenCheck && !isFilter && config.data && isOpenRsa && type != '[object FormData]') {
      config.data = config.data + "&sign=1"
      config.data = decodeURIComponent(config.data)
      var formDataBase64 = Base64.encode(config.data)
      var rsaInfo = rsa.encryptByPublicLongKey(formDataBase64);
      var encryptInfo = Base64.encode(rsaInfo);
      config.data = 'encryptionValueInfo=' + encryptInfo;
    }
    var token = localStorage.getItem('dftToken');
    if (token) {
      config.headers.Authorization = token
    }
    if (getToken()) {
      config.headers['GIFT_WEB_SYSTEM_TOKEN'] = getToken()
      config.headers['sign'] = 'GIFT_WEB_SYSTEM_TOKEN'
    }
    config.headers['tenantId'] = localStorage.getItem("tenantInfoid")
    return config
  },
  err => {
    return Promise.reject(err)
  },
)


axios.interceptors.response.use(
  response => {
    return response
  },
  error => {
    if (error.response) {
      switch (error.response.status) {
        case 401:

          localStorage.removeItem('dftToken');
          localStorage.removeItem('phone');

          router.currentRoute.path !== 'login' &&
            router.replace({
              path: '/login',
              query: { redirect: router.currentRoute.path },
            })
      }
    }

    return Promise.reject(error)
  },
)

export default axios
