import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import lrz from 'lrz'
import '@/plugins/element/element.js'
import Date from './utils/Date'
import "./utils/auto-update"

import {Base64} from 'js-base64'
Vue.use(Base64)

import qs from 'qs'
Vue.prototype.$qs = qs

import proxyConfig from '@/conf/proxyConfig'
Vue.prototype.$proxyConfig = proxyConfig


import myPlugin from '@/components/index.js'
Vue.use(myPlugin)

Vue.filter('date', Date)



import time from '@/utils/time_utils'
Vue.prototype.$time = time

import check from '@/utils/check_utils'
Vue.prototype.$check = check

import img_utils from '@/utils/img_utils'
Vue.prototype.$imgUtils = img_utils

import axios from './http'
Vue.prototype.$axios = axios
import request from './utils/request'
Vue.prototype.$request = request

import {fullPath} from '@/utils/filters.js'
Vue.prototype.$fullPath = fullPath
Vue.filter('fullPath', fullPath)

import common from '@/utils/common'
Vue.prototype.$common = common

import VueAwesomeSwiper from 'vue-awesome-swiper'
import 'swiper/dist/css/swiper.css'
Vue.use(VueAwesomeSwiper, )

Vue.config.productionTip = false

import moment from 'moment'
Vue.prototype.$moment = moment


new Vue({
    router,
    store,
    render: h => h(App)
}).$mount('#app')
