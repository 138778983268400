export default {
	isPhone(phone) {
		var reg = /^1[3-9]\d{9}$/;

		if (phone.length == 16 && phone.includes('-')) {
			return true
		}

		if (reg.test(phone)) {
			return true;
		} else {
			return false;
		}
	},

	isPhoneStrict(phone) {
		var reg = /^(?:(?:\+|00)86)?1(?:(?:3[\d])|(?:4[5-7|9])|(?:5[0-3|5-9])|(?:6[5-7])|(?:7[0-8])|(?:8[\d])|(?:9[1|8|9]))\d{8}$/g;
		if (reg.test(phone)) {
			return true;
		} else {
			return false;
		}
	},
	numandenglish(str) {
		var reg = /^[A-Za-z0-9]+$/;
		if (reg.test(str)) {
			return str;
		} else {
			return str.replace(/[^\w\.\/]/ig, '')
		}
	},
	isEmail(email) {
		var reg = /^\w+([-+.]\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/;
		if (reg.test(email)) {
			return true;
		} else {
			return false;
		}
	},
	noChinese(text) {
		var reg = /^[\u4e00-\u9fa5]{0,}$/;
		if (reg.test(text)) {
			return '';
		} else {
			return text;
		}
	},
	isInteger(num) {
		var reg = /^[1-9]\d*$/;
		if (reg.test(num)) {
			return num;
		} else {
			return num.replace(/[^\d]/g, '');
		}
	},
	isPositive(num, min, max) {
		var reg = /^\d+(\.\d+)?$/;

		var n = (num.split('.')).length - 1;

		if (n > 1) {

			return num.substr(0, num.length - 1);

		}

		if (num.indexOf(".") != -1 && num.indexOf(".") == (num.length - 1)) {

			if (num.length > 1) {
				return num;
			} else {
				return ''
			}

		}

		else if (reg.test(num)) {

			if (min && Number(num) < min) return min;
			if (max && Number(num) > max) return max;

			if (num.indexOf(".") != -1 && num.split('.')[1].length > 2) {
				return Number(num).toFixed(2);
			}
			if (Number(num) >= 0) return num;
			return '';
		}

		else {
			return num.replace(/[^\d(\.\d)?$]/g, '');
		}
	},
	isLink(link) {
		if (link.indexOf('https://') == -1 && link.indexOf('http://') == -1) {
			return false;
		} else {
			return true;
		}
	},
	isBanktNumber(value) {
		if (/^[1-9]\d{9,29}$/g.test(value)) {
			return value;
		} else {
			return '';
		}
	},
	isLandlineTelephone(value) {
		if (/\d{3}-\d{8}|\d{4}-\d{7}/g.test(value)) {
			return value;
		} else {
			return '';
		}
	},
	isIDCard(value) {
		if (/(^\d{8}(0\d|10|11|12)([0-2]\d|30|31)\d{3}$)|(^\d{6}(18|19|20)\d{2}(0\d|10|11|12)([0-2]\d|30|31)\d{3}(\d|X|x)$)/g.test(value)) {
			return value;
		} else {
			return '';
		}
	},
	isPassport(value) {
		if (/(^[EeKkGgDdSsPpHh]\d{8}$)|(^(([Ee][a-fA-F])|([DdSsPp][Ee])|([Kk][Jj])|([Mm][Aa])|(1[45]))\d{7}$)/g.test(value)) {
			return value;
		} else {
			return '';
		}
	},
}
