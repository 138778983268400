import Vue from 'vue'
import Vuex from 'vuex'
import request from '../utils/request'

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        userInfo: {
            id:'',
            info:{},
            level:'',
        },
        isLogin: false,
        tenantInfo: {},
        goodsInfo: {},
        params:{
        },
        showOtherSite: true,
        showMeAd:true,
        aboutShow: true ,
        filterUrlInfo:'',
        isOpenCheck: '0'
    },
    mutations: {
        getGoodsInfo(state,obj){
            state.goodsInfo = obj
        },
        getUserInfo(state,obj){
            if(obj && obj.info && obj.info.phone) {
                state.userInfo.id = obj.info.id;
                state.userInfo.info = obj.info;
                state.userInfo.level = obj.level;
            } else {
                state.isLogin = false;
            }
        },
        setIsLogin(state,value){
            state.isLogin = value
        },
        getTenantInfo(state){
            const locations = process.env.NODE_ENV == "development" ? "www.guyunm.com" : window.location.host
            request.post({
                url:'/ReportApi/tenantManager/tenantInfo',
                params:{
                    locations
                },
                success: res => {
                    state.tenantInfo = res;
                    localStorage.setItem("tenantInfoid", res.id);

                },
                finally: () => {
                }
            })
        },
        getParams(state){
            request.post({
                url:'/ReportApi/business/basic/showParams',
                params:{},
                success: res => {
                    state.params = res;
                    console.log()
                    var hostName = window.location.hostname;
                    if(res.no_show_other_site != undefined && res.no_show_other_site.indexOf(hostName) != -1){
                        state.showOtherSite = false;
                    }
                    if(res.no_show_me_ad != undefined && res.no_show_me_ad.indexOf(hostName) != -1){
                        state.showMeAd = false;
                    }
                    if(res.now_show_about_ad != undefined && res.now_show_about_ad.indexOf(hostName) != -1){
                        state.aboutShow = false
                    }
                    if(res.filterUrl) {
                      var url = res.filterUrl.split(";")
                      state.filterUrlInfo = url
                    }
                    if(res.isOpenCheck){
                      state.isOpenCheck = res.isOpenCheck
                    }

                },
                finally: () => {
                }
            })
        }
    },
    actions: {

    },
    modules: {
    }
})
