import { Notification } from 'element-ui';

let lastSrcs;

const scriptReg = /\<script.*src=["'](?<src>[^"']+)/gm;

async function extractNewScript() {
  const html = await fetch('/?_timestamp=' + Date.now()).then(resp => {
    resp.text()
  });
  scriptReg.lastIndex = 0
  let result = []
  let match;
  while ((match = scriptReg.exec(html))) {
    result.push(match.groups.src)
  }
  return result
}

async function needUpdata() {
  const newScripts = await extractNewScript()
  if (!lastSrcs) {
    lastSrcs = newScripts
    return false
  }

  let result = false;
  if (lastSrcs.length !== newScripts.length) {
    result = true
  }

  for (let i = 0; i < lastSrcs.length; i++) {
    if (lastSrcs[i] !== newScripts[i]) {
      result = true
      break
    }
  }
  lastSrcs = newScripts
  return result
}

const DURATION = 5000

function autoRefresh() {
  setTimeout(async () => {
    const willUpdate = await needUpdata()
    if (willUpdate) {
      Notification({
        title: "温馨提示",
        message: "网站升级,建议您及时刷新当前页面获取最新服务",
        duration: 3000,
        position: 'bottom-right',
        onClick: () => {
          location.reload()
        }
      })
    } else {
      console.log('无需更新')
    }
    autoRefresh()
  }, DURATION)
}

autoRefresh()