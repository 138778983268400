
const fullPath = function(value){
	if(value && value.indexOf('http')!=-1){
		return value;
	}
	var host = document.location.host;
	var result = "";
	if(value && value.indexOf('files')!=-1 ){
		if(host.search('192')!=-1){
			result = 'http://127.0.0.1:8080' + value
		}else{
			result = document.location.protocol + "//" + host + value
		}
	}else{
		result = value;
	}
	return result;
}


const lazyLoad = function(value){
	var host = document.location.host;
	var result = "";
	if(value.src && (value.src.search('upload/') != -1||value.src.search('resources/') != -1)){
		if(host.search('www.') != -1||host.search('backend.') != -1 ){
			result = document.location.protocol + "//" + host + '/' + value
		}else{
			host = '192.168.0.77:8083/'
			result = document.location.protocol + "//" + host + value.src
		}
	}else if(value.src && value.src.search('upload/') == -1&&value.src.search('resources/') == -1){
		result = value.src;
	}
	value.src = result
}


export {
	fullPath,
	lazyLoad
};
